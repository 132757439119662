import { BsArrowRight } from "react-icons/bs";
import styled from "styled-components";
import { Button } from "components/button";
import { responsive } from "../../theme/reponsive";

export const StudyWrapper = styled.div`
   background-color: #fafafa;

   ${responsive("$medium")`
   padding: 2.7rem 1.5rem 5rem;
`}
`;

export const RelatedWrapper = styled.div`
   background-color: #fafafa;
   @media screen and (max-width: 990px) {
      padding: 2.7rem 3rem 5rem;
   }
`;

export const RelatedBox = styled.div`
   display: grid;
   grid-template-columns: repeat(4, 30rem);
   grid-column-gap: 3.7rem;

   div.relatedCard {
      margin: 0;
   }

   @media screen and (max-width: 990px) {
      display: block;
      div.relatedCard {
         margin-bottom: 3rem;
      }
   }
`;

export const CustomButton = styled(Button)`
   width: 10.8rem;
   height: 3.6rem;
   font-size: 1.4rem;
`;

export const StudyAbroadWrapper = styled.div`
   display: flex;
   margin: 4.6rem 0;

   @media screen and (max-width: 990px) {
      flex-direction: column-reverse;
   }
`;

export const StudyGrid = styled.div`
   display: grid;
   grid-template-columns: repeat(4, 1fr);
   gap: 3rem;

   .study-card {
      max-width: 100%;
      // min-width: 300px;

      .image-div {
         width: 100%;
         height: 17.7rem;
         position: relative;
         filter: drop-shadow(0px 12px 70px rgba(204, 204, 204, 0.4));
         img {
            border-radius: 10px 10px 0 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
         }
      }
      .content-div {
         padding: 1.6rem 1.5rem 1.6rem;
         background-color: #f7f7f7;
         box-shadow: 0px 12px 70px rgba(204, 204, 204, 0.4);
         height: 19rem;
         display: flex;
         flex-direction: column;
         justify-content: space-between;
         background-color: #fff;

         p {
            font-size: 14px;
            line-height: 18px;
            color: #9d9595;
         }
         p.desc {
            font-weight: 500;
            font-size: 16px;
            line-height: 20px;
            text-transform: uppercase;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            overflow: hidden;
            margin-bottom: 1.5rem;
         }
         p.title {
            text-transform: capitalize;
            font-weight: 500;
            font-size: 16px;
            line-height: 20px;
            color: #444444;
            margin-bottom: 0.5rem;
         }
         p.duration {
            margin-bottom: 0.5rem;
         }
         p.age {
            margin-bottom: 0.5rem;
         }

         .action-div {
            display: flex;
            align-items: center;
            justify-content: space-between;
         }

         .link {
            cursor: pointer;
            display: flex;
            align-items: center;
            p {
               font-weight: 500;
               font-size: 14px;
               line-height: 18px;
               margin-right: 1.1rem;
               color: ${({ theme }) => theme.colors?.primary};
            }
         }
      }
   }

   ${responsive("$small")`
   width: 100%;
   display: block;
   .study-card {
      margin-bottom: 3rem;
      .content-div {
         height: auto;
         min-height: 21.7rem;
      }
   }
`}
`;

export const PersonnelSection = styled.div`
   background-color: red;
   height: 200px;
`;

export const Arrow = styled(BsArrowRight)`
   font-size: 25px;
   color: ${({ theme }) => theme.colors?.primary};
`;

export const FiltersSection = styled.div`
   max-width: 300px;
   width: 300px;
   margin-right: 3.2rem;
   @media screen and (max-width: 990px) {
      width: 100%;
      margin: 0;
      max-width: 100%;
   }
`;

export const BannerWrapper = styled.div<{ url: string }>`
   background-image: url(${({ url }) => url});
   min-height: 200px;
   height: 337px;
   background-position: center;
   background-size: cover;
   background-repeat: no-repeat;
   width: 100%;
   display: flex;
   flex-direction: column;
   align-items: flex-start;
   justify-content: center;
   padding-left: 65px;

   p.banner-text {
      font-size: 35px;
      line-height: 44px;
      color: #fff;
      margin-top: 2.6rem;
      span {
         color: #ffae33;
         text-decoration: underline;
      }
   }

   h1 {
      font-size: 7rem;
      color: #fff;
      line-height: 89px;
   }
   @media screen and (max-width: 990px) {
      height: 350px;
      padding-left: 2.5rem;
      h1 {
         font-size: 4rem;
      }
   }
`;

export const NewBannerWrapper = styled.div`
   padding-left: 5rem;
   padding-right: 7rem;
   height: 589px;
   background: #fafafa;
   display: flex;
   align-items: center;
   justify-content: space-between;
   position: relative;
   background-color: #000;
   overflow: hidden;
   background-color: #fff;
   box-shadow: 0 3px 15px rgba(0, 0, 0, 0.1);
   background-position: center;
   background-size: cover;
   width: 100%;
   // z-index: -1;

   ${responsive("$xsmall")`
   padding: 0 3rem;
`}
`;

export const RightContent = styled.div`
   position: absolute;
   width: 50%;
   height: 100%;
   right: 0;
   bottom: -15%;
   align-self: flex-end;
   padding-right: 50px;

   ${responsive("$small")`
   display: none;
`}
`;

export const LeftContent = styled.div``;

export const FlexBox = styled.div`
   display: flex;
   align-items: center;
   gap: 2rem;
   margin: 2.5rem 0;

   ${responsive("$small")`
   align-items: flex-start;
   flex-direction: column;
   gap: 1rem;
`}
`;

export const FlexBetween = styled.div`
   display: flex;
   width: 100%;
   justify-content: space-between;
`;

export const ImagesGroup = styled.div`
   margin: 3rem 0;
   display: flex;
   position: relative;
`;

export const JumbotronWrapper = styled.div`
   background: rgba(53, 120, 229, 0.05);
   padding: 5rem 0;
   display: flex;
   flex-direction: column;
   align-items: center;

   & .heading {
      color: #444444;
      text-align: center;
      margin-bottom: 4rem;
   }
`;

export const SimpleFeatures = styled.div`
   display: flex;
   justify-content: center;
   gap: 29.8rem;

   ${responsive("$medium")`
   flex-direction: column;
   gap: 4rem;
`}
`;

export const FeatureBlock = styled.div`
   display: flex;
   flex-direction: column;
   align-items: center;

   .brief {
      max-width: 200px;
      color: #666666;
      font-weight: 500;
   }
`;

export const SimpleWrapper = styled.div`
   display: flex;
   flex-direction: column;
   align-items: center;
   text-align: center;
   padding: 5rem;
   gap: 3rem;
`;

export const StudyGroupWrapper = styled.div`
   padding: 5em;
   background: #fafafa;

   ${responsive("$medium")`
   padding: 0 3rem;
`}
`;

export const StudyBlock = styled.div`
   margin: 3rem 0;
   display: grid;
   grid-template-columns: repeat(2, 1fr);
   gap: 5rem;
   width: 100%;

   &.reverse {
      display: flex;
      flex: 1;
      justify-content: space-between;
      flex-direction: row-reverse;

      div {
         width: 50%;
      }

      ${responsive("$medium")`
      display: grid;
      grid-template-columns: repeat(1, 1fr); 

      div {
         width: 100%;
      }
   `}
   }

   .end {
      display: flex;
      ${responsive("$medium")`
      justify-content: center;
   `}
   }

   ${responsive("$medium")`
   grid-template-columns: repeat(1, 1fr); 
   gap: 3rem;
`}
`;

export const TextBlock = styled.div`
   align-self: center;
   max-width: 100%;

   .caption {
      color: #444444;
      line-height: 1.4;
      max-width: 550px;
      margin-bottom: 0.5em;
   }

   ${responsive("$medium")`
   display: flex;
   gap: 1.5rem;
   flex-direction: column;
   align-items: center;
  
   & .caption {
      text-align: center;
   }
`}
`;

export const GalleryWrapper = styled.div`
   height: 900px;
   position: relative;
`;

export const ImageBox = styled.div`
   &.middle {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
   }
`;

export const CtaWrapper = styled.div<{ url: string }>`
   background-image: url(${({ url }) => url});
   min-height: 200px;
   height: 337px;
   background-position: center;
   background-size: cover;
   background-repeat: no-repeat;
   width: 100%;
   display: flex;
   align-items: center;
   padding-left: 65px;

   h2 {
      font-size: 5rem;
      width: 58.3rem;
      color: #fff;
      line-height: 6.3rem;
   }

   ${responsive("$small")`
   height: 250px;
   padding-left: 30px;
   h2 {
      font-size: 3rem;
      line-height: 3.4rem;
   }
`}
`;

export const DetailsWrapper = styled.div`
   padding-top: 2rem;
   p {
      text-transform: capitalize;
      span {
         font-weight: bold;
      }
      &:not(:last-of-type) {
         margin-bottom: 2rem;
      }
   }
`;

export const FilterWrapper = styled.div<{ multi?: boolean | undefined }>`
   background-color: #fff;
   border-radius: 1rem;
   min-height: 18.5rem;
   width: 100%;
   padding: 3.1rem 1.3rem 6.1rem 2.4rem;
   ${({ multi }) => multi && "height: 28rem; margin-top: 4.8rem; overflow-y: scroll;"}

   .flex-center {
      display: flex;
      align-items: center;
      &:not(:last-of-type) {
         margin-bottom: 2rem;
      }
   }
   p {
      font-size: 16px;
      line-height: 23px;
      color: #333366;
      margin-bottom: 2.4rem;
   }
`;

export const PostWrapper = styled.div`
   margin-top: 4rem;
`;

export const AdvertCard = styled.div`
   border-radius: 10px;
   box-shadow: 0px 4px 70px rgba(204, 204, 204, 0.4);
   background-color: #fff;
   &:not(:last-of-type) {
      margin-bottom: 4.5rem;
   }
   .image-div {
      height: 20rem;
      width: 100%;
      position: relative;
      div {
         width: 100%;
      }
      img {
         border-radius: 10px 10px 0 0;
         height: 20rem;
         width: 100%;
         object-fit: cover;
      }
   }
   .text-div {
      padding: 3rem 2rem 1.8rem 1.9rem;
      /* height: 200px; */

      p {
         font-weight: 500;
         font-size: 18px;
         line-height: 23px;
         color: #666666;
      }
   }
`;

export const SingleSummerWrapper = styled.div`
   padding: 5.9rem 9.3rem 12.7rem 9.2rem;
   background-color: #fafafa;
   @media screen and (max-width: 990px) {
      padding: 2rem 3rem 5rem;
   }
`;

export const ImageDiv = styled.div`
   width: 100%;
   height: 50rem;
   position: relative;
   img {
      height: 100%;
      width: 100%;
   }
   @media screen and (max-width: 990px) {
      height: 20rem;
   }
`;

export const MainDescription = styled.div`
   margin-top: 4.8rem;
   color: #666;

   h4,
   p:not(:last-of-type) {
      margin-bottom: 10px;
   }
`;

export const Container = styled.div`
   max-width: 1440px;
   margin: 0 auto;
`;

export const SuccessWrapper = styled.div`
   display: flex;
   flex-direction: column;
   gap: 3rem;
   align-items: center;

   .description {
      text-align: center;
      max-width: 514px;
      color: #444444;
      font-size: 18px;
   }
`;

export const TopWrapper = styled.div<{ url?: string }>`
   background-image: url(${({ url }) => url});
   min-height: 200px;
   height: 337px;
   background-position: center;
   background-size: cover;
   background-repeat: no-repeat;
   width: 100%;
   display: flex;
   padding-left: 85px;
   flex-direction: column;
   justify-content: center;

   ${responsive("$small")`
   padding-left: 2rem;
`}

   h1 {
      position: absolute;
      color: white;
      z-index: 2;
      font-size: clamp(18px, 5.5vw, 60px);
      font-weight: 400;
   }
`;

export const PricingGrid = styled.div`
   .desktop {
      display: grid;
      gap: 3rem;
      grid-template-columns: 50% 50%;
      max-width: 1300px;
   }
   .mobile {
      display: none;
   }

   @media screen and (max-width: 990px) {
      .desktop {
         display: none;
      }
      .mobile {
         width: 100%;
         display: block;
      }
   }
`;

export const PricingWrapper = styled.div`
   padding: 100px 0;
   position: relative;
   background-color: #fafafa;

   @media screen and (max-width: 767px) {
      padding: 50px 0;
   }

   .shapes {
      position: absolute;
      right: 0;
      top: -100px;
      height: 151px;

      @media screen and (max-width: 767px) {
         top: -50px;
      }
   }
`;

export const FeatureDisplayWrapper = styled.div`
   max-width: 512px;

   .title {
      font-weight: bold;
      font-size: 36px;
      line-height: 46px;
      margin-bottom: 39px;
      text-transform: capitalize;
   }

   .features {
      display: flex;
      width: 100%;
      align-items: flex-start;

      &:not(:last-of-type) {
         margin-bottom: 23px;
      }

      p.text {
         font-size: 16px;
         line-height: 20px;
         margin-left: 9px;
      }

      div.icon {
         height: 19px;
         width: 19px;
      }
   }
   .btns-div {
      margin-top: 51px;
      button {
         border-radius: 8px;
         width: 227px;
      }
   }

   @media screen and (max-width: 990px) {
      width: 90%;
      margin: 0 auto;
   }
`;

export const FeatureCardWrapper = styled.div`
   max-width: 535px;
   z-index: 1;
   .btns-div {
      display: flex;
      height: 66px;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      background-color: rgba(255, 255, 255, 1);
      border-radius: 10px;
      padding: 0 15px;
   }

   .cards-div {
      margin-top: 34px;
      width: 100%;
   }
   @media screen and (max-width: 990px) {
      /* width: 100%; */
      margin: 0 auto;
   }

   @media screen and (max-width: 550px) {
      width: 90%;
      margin: 0 auto;
      .btns-div {
         /* width: 300px; */
         flex-wrap: wrap;
         height: auto;
         padding: 18px 15px 17px;
         justify-content: space-around;
      }

      .cards-div {
         margin-top: 34px;
         width: 100%;
      }
   }
`;

export const FeatureCardDiv = styled.div`
   display: flex;
   background-color: #fff;
   height: 105px;
   width: 100%;
   border-radius: 5px;
   cursor: pointer;

   &:not(:last-of-type) {
      margin-bottom: 26px;
   }

   .side {
      width: 12px;
      border-radius: 5px 0 0 5px;
      background-color: #fff;
      transition: background 0.5s ease-in-out;
   }

   .active {
      background-color: ${({ theme }) => theme.colors?.primary};
      transition: background 0.5s ease-in-out;
   }

   .content {
      display: flex;
      height: 100%;
      align-items: center;
      justify-content: space-between;
      padding: 0 38px 0 27px;
      width: calc(100% - 12px);

      .title {
         font-size: 18px;
         font-weight: 700;
         text-transform: uppercase;
         line-height: 23px;
         margin-bottom: 5px;
      }

      .desc {
         color: #959595;
         line-height: 20px;
         font-size: 14px;
         width: 316px;
      }

      .price {
         color: ${({ theme }) => theme.colors?.primary};
         font-weight: bold;
         font-size: 25px;
         line-height: 32px;
      }
   }

   @media screen and (max-width: 550px) {
      .content {
         padding: 0 15px;
         .desc {
            width: 95%;
            font-size: 13px;
         }

         .price {
            font-size: 20px;
         }
      }
   }
`;

export const MobileFeatureDisplayWrapper = styled.div`
   width: 100%;
   height: 80vh;

   .title {
      height: 10rem;
      width: 100%;
      background: #3578e5;
      margin-bottom: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      h5 {
         font-weight: 700;
         font-size: 20px;
         line-height: 25px;
         color: #ffffff;
         text-transform: uppercase;
      }
   }

   .main-content {
      width: 100%;
      padding: 3rem;
      .flex-desc {
         width: 100%;
         display: flex;
         justify-content: center;
         align-items: center;
         font-weight: 700;
         .amount {
            font-size: 40px;
            line-height: 61px;
            color: #666666;
            margin-bottom: 3rem;
         }
         .duration {
            color: #666666;
            font-size: 20px;
         }
      }
      .features {
         display: flex;
         align-items: center;
         gap: 15px;
         width: 100%;

         &:not(:last-of-type) {
            margin-bottom: 10px;
         }

         .text-content {
            font-size: 16px;
            line-height: 20px;
         }

         .icon-area {
            height: 19px;
            width: 19px;
         }
      }
      .btns-div {
         margin-top: 21px;
         button {
            border-radius: 8px;
            width: 200px;
            height: 5rem;
         }
      }
   }
`;
