import styled from "styled-components";

export const NonDashboardContainer = styled.div`
   // background-color: red;
`;

export const NonDashboardWrapperChildren = styled.div`
   min-height: 80vh;
   width: 100% !important;
   margin-top: 5rem;
   padding: 2rem 0;
   a {
      text-decoration: none;
   }
`;
