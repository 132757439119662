import Image from "next/image";
import Link from "next/link";
import { RiArrowUpSLine } from "react-icons/ri";
import { nigeriaLinkData, ghanaLinkData, footerSocials, footerDataFour, OtherFooterLinks } from "./Footer-data";
import {
   FooterWrapper,
   Socials,
   LogoHolder,
   FooterItems,
   FooterItemsOne,
   FooterItemsTwo,
   FooterItemsThree,
   BorderBottomLine,
   FooterBottom,
} from "./footer.style";

const scrollTop = () => {
   window.scrollTo({ top: 0, behavior: "smooth" });
};

const Footer = () => {
   return (
      <FooterWrapper>
         <FooterItems>
            <Socials>
               <LogoHolder className="Logo">
                  <img
                     src="/landing-page/edusko_weblogo.png"
                     id="Logo"
                     alt="edusko"
                     className="logo"
                  />
               </LogoHolder>
               <p className="info-paragragh">
                  Africa’s largest education market place for best schools. parents, students,
                  vendors, lenders. and many more.
               </p>
               <div style={{ display: "flex", gap: "2rem" }}>
                  {footerSocials.map((icon, index) => {
                     return (
                        <a href={icon.link} key={index}>
                           <Image src={icon.image} alt={icon.alt} layout="fixed" />
                        </a>
                     );
                  })}
               </div>
               <BorderBottomLine />
               <FooterItemsThree>
                  {footerDataFour.map((data, index) => {
                     return (
                        <Link href={data.link} key={index}>
                           <a>{data.title}</a>
                        </Link>
                     );
                  })}
               </FooterItemsThree>
            </Socials>
            <FooterItemsOne>
               {nigeriaLinkData.map((data, index) => {
                  return (
                     <Link href={data.link} key={index}>
                        <a>{data.title}</a>
                     </Link>
                  );
               })}
            </FooterItemsOne>
            <FooterItemsTwo>
               {ghanaLinkData.map((data, index) => {
                  return (
                     <Link href={data.link} key={index}>
                        <a>{data.title}</a>
                     </Link>
                  );
               })}
            </FooterItemsTwo>
            <FooterItemsOne>
               {OtherFooterLinks.map((data, index) => {
                  return (
                     <Link href={data.link} key={index}>
                        <a>{data.title}</a>
                     </Link>
                  );
               })}
            </FooterItemsOne>
            {/* <FooterItemsThree>
               {footerDtataThree.map((data, index) => {
                  return (
                     <Link href={data.link} key={index}>
                        <a>{data.title}</a>
                     </Link>
                  );
               })}
            </FooterItemsThree> */}

            <button onClick={scrollTop}>
               <RiArrowUpSLine size={35} />
            </button>
         </FooterItems>
         <FooterBottom>
            <div className="bottom-main">
               <p>Lagos, London, Delaware</p>
               <p>
                  <a href="mailto:info@edusko.com">info@edusko.com</a>
               </p>
               <p className="mobile">
                  <a href="tel:+234-7052704253">+234-7052704253</a>
               </p>
               <p className="copy">&copy; {new Date().getFullYear()} EDUSKO Inc.</p>
            </div>
         </FooterBottom>
      </FooterWrapper>
   );
};

export default Footer;
