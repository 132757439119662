import fbLogo from "../../assets/images/facebook.png";
import instaImage from "../../assets/images/instagram.png";
import twitterImage from "../../assets/images/twitter.png";

const ghanaUrl = `school/search?`;
const nigeriaUrl = `school/search?`;

export const nigeriaLinkData = [
   { title: "NIGERIA", link: `/${nigeriaUrl}search=nigeria` },
   { title: "Schools In Nigeria", link: `/${nigeriaUrl}search=nigeria` },
   { title: "Top Private Schools In Lagos", link: `/${nigeriaUrl}state=lagos&country=nigeria` },
   { title: "Top Private Schools In Ibadan", link: `/${nigeriaUrl}state=oyo&country=nigeria` },
   { title: "Top Private Schools In Abuja", link: `/${nigeriaUrl}state=federal capital territory&country=nigeria` },
   {
      title: "Top Private Schools In Port Harcourt",
      link: `/${nigeriaUrl}search=rivers&country=nigeria`,
   },
   { title: "Top Private Schools In Abeokuta", link: `/${nigeriaUrl}state=ogun&country=nigeria` },
   {
      title: "Primary Schools In Nigeria",
      link: `/${nigeriaUrl}category=nursery_and_primary&country=nigeria`,
   },
   {
      title: "Boarding Schools in Nigeria",
      link: `/${nigeriaUrl}category=boarding_only&country=nigeria`,
   },
   {
      title: "Secondary Schools in Nigeria",
      link: `/${nigeriaUrl}category=high_school&country=nigeria`,
   },
];

export const ghanaLinkData = [
   { title: "GHANA", link: `/${ghanaUrl}search=ghana` },
   { title: "Schools In Ghana", link: `/${ghanaUrl}` },
   { title: "Top Private Schools In Accra", link: `/${ghanaUrl}search=accra&country=ghana` },
   { title: "Top Private Schools In Ashanti", link: `/${ghanaUrl}search=ashanti&country=ghana` },
   {
      title: "Top Private Schools In Eastern Region",
      link: `/${ghanaUrl}search=eastern region&country=ghana`,
   },
   {
      title: "Top Private Schools In Central Region",
      link: `/${ghanaUrl}search=central region&country=ghana`,
   },
   {
      title: "Top Private Schools In Northern Region",
      link: `/${ghanaUrl}search=northern region&country=ghana`,
   },
   {
      title: "Top Private Schools In Upper West Region",
      link: `/${ghanaUrl}&search=upper west region&country=ghana`,
   },
   {
      title: "Top Private Schools In Volta Region",
      link: `/${ghanaUrl}search=volta region&country=ghana`,
   },
   { title: "Secondary Schools in Ghana", link: `/${ghanaUrl}category=high_school&country=ghana` },
   {
      title: "Primary Schools In Ghana",
      link: `/${ghanaUrl}category=nursery_and_primary&country=ghana`,
   },
   { title: "Tertiary Schools in Ghana", link: `/${ghanaUrl}category=tertiary&country=ghana` },
];

// export const footerDtataThree = [
//    { title: "EdCommerce", link: "/edcommerce" },
//    { title: "Join EdCommerce as a vendor", link: "/edcommerce/signup" },
// ];

export const footerDataFour = [
   { title: "OTHER LINKS", link: "#" },
   { title: "Summer Camp", link: "/summer-camp" },
   { title: "Edusko Board", link: "/advisory" },
   { title: "Pricing Plans for Schools", link: "/pricing" },
   { title: "Bournvita Tech Bootcamp", link: "/bournvitatechbootcamp" },
   { title: "Business of Education", link: "/boe" },
   { title: "Africa Education Fest UK", link: "/afredfest" },
   { title: "Dulux Art Festival", link: "/duluxartfest" },
   { title: "About", link: "/about-us" },
   { title: "FAQ", link: "/faq" },
];

export const footerSocials = [
   {
      image: fbLogo,
      alt: "facebook",
      link: "https://www.facebook.com/eduskoafrica",
   },
   {
      image: twitterImage,
      alt: "twitter",
      link: "https://twitter.com/EduskoAfrica",
   },
   {
      image: instaImage,
      alt: "instagram",
      link: "https://www.instagram.com/eduskoafrica",
   },
];

export const OtherFooterLinks = [
   { title: "MORE LINKS", link: "#" },
   { title: "Terms of Use", link: "/t&c" },
   { title: "Privacy Policy", link: "/privacy-policy" },
   { title: "Edusko Ghana", link: "/ghana" },
   { title: "Edusko Kenya", link: "/kenya" },
   { title: "Edusko Nigeria", link: "/" },
   { title: "Edusko Blogs", link: "/blog" },
   { title: "Activated Page", link: "/activated" },
];
