import Head from "next/head";

interface Props {
   title: string;
   children: React.ReactNode;
   description?: string;
   image?: string;
   slug?: string;
}

export const AppContainer = ({ title, children, description, image, slug }: Props) => {
   return (
      <>
         <Head>
            <title>{title ? title : "Edusko"}</title>
            <meta name="description" content={description} />
            <link rel="canonical" href={slug} />
            <meta property="og:url" content={slug} />
            <meta property="og:title" content={title} />
            <meta property="og:description" content={description} />
            <meta property="og:image" content={image} />
            <meta property="og:image:width" content="" />
            <meta property="og:image:height" content="" />
            <meta property="og:type" content="website" />
            <meta name="twitter:title" content={title} />
            <meta name="twitter:description" content={description} />
            <meta name="twitter:image" content={image} />
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:image:alt" content={title} />
            <meta name="twitter:site" content="" />
            <meta charSet="utf-8" />
            <meta name="viewport" content="initial-scale=1.0, width=device-width" />
            <link rel="icon" href="/favicon.ico" />
         </Head>
         <>{children}</>
      </>
   );
};
