import styled from "styled-components";

export const FooterWrapper = styled.div`
   display: grid;
   width: 100%;
   padding: 5rem;
   background: #fff;
   position: relative;
   bottom: 0;
   box-shadow: -2px -6px 7px rgb(0 0 0 / 1%);

   p,
   a {
      font-size: 1.4rem;
      font-weight: 500;
   }

   @media screen and (max-width: 990px) {
      display: block;
   }

   @media screen and (max-width: 468px) {
      max-width: 100%;
      padding: 2rem;
   }
`;

export const Socials = styled.div`
   a {
      margin-right: 25px;
   }

   p {
      /* padding: 10px 0px; */

      a {
         color: #3578e5;
      }
   }

   @media screen and (max-width: 990px) {
      width: 100%;
      margin: 30px 2rem;
      text-align: center;
   }

   @media screen and (max-width: 468px) {
      width: 100%;
      margin: 0;
      text-align: left;
      margin-bottom: 15px;
   }
`;

export const LogoHolder = styled.div`
   div {
      height: clamp(33px, 5.5vw, 35px);
   }
   img {
      width: 180px;
   }
`;

export const FooterItems = styled.div`
   // grid-column: 2/3;
   padding: 20px 100px;
   display: flex;
   justify-content: space-between;

   a {
      display: block;
      margin: 1px;
      cursor: pointer;
      text-decoration: none;
      color: black;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
   }
   .info-paragragh {
      width: 350px;
   }

   a:first-child {
      color: #151f34;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
   }
   a:hover {
      color: #3578e5;
   }

   button {
      width: clamp(33px, 5.5vw, 40px);
      border-radius: 100%;
      height: clamp(33px, 5.5vw, 40px);
      color: white;
      border: none;
      background: #3578e5;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: transform 300ms ease-in-out;
      cursor: pointer;
   }

   button:hover,
   button:focus {
      transform: scale(1.05);
      background: rgba(8, 114, 253, 0.781);
      border: none;
      outline: none;
   }

   @media screen and (max-width: 990px) {
      display: flex;
      padding: 20px;

      button {
         position: absolute;
         bottom: 2rem;
         right: 2rem;
      }
      .info-paragragh {
         width: 100%;
      }
   }

   @media screen and (max-width: 650px) {
      display: flex;
      flex-direction: column;
      text-align: center;
      row-gap: 20px;
      padding: 20px;

      button {
         position: absolute;
         bottom: 2rem;
         right: 2rem;
      }
      .info-paragragh {
         width: 100%;
      }
   }

   @media screen and (max-width: 468px) {
      text-align: left;
      padding: 20px;
      .info-paragragh {
         width: 100%;
      }
   }
`;

export const FooterItemsOne = styled.div``;

export const FooterItemsTwo = styled.div``;

export const FooterItemsThree = styled.div`
   margin-top: 50px;
`;

export const BorderBottomLine = styled.div`
   border-bottom: 2px solid black;
   width: 200px;
   margin-top: 20px;
`;

export const FooterBottom = styled.div`
   padding-bottom: 100px;
   width: 100%;
   display: flex;
   justify-content: center;
   .bottom-main {
      text-align: center;
      p {
         color: #6b7280;
         font-weight: 400;
         font-size: 18px;
         line-height: 20px;
         margin-bottom: 3px;
      }
      .mobile a {
         text-decoration: none;
         color: #6b7280;
      }
      .copy {
         font-size: 18px;
         font-weight: 600;
         color: #264ffa;
         line-height: 20px;
      }
   }
`;
