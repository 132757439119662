import styled from "styled-components";

export const BlogCardContainer = styled.a`
   display: grid;
   margin: auto;
   cursor: pointer;
`;

export const UpdatedBlogCardContainer = styled.div`
   background: #fff;
   width: 24%;
   height: 100%;
   overflow: hidden;
   cursor: pointer;

   box-shadow: 0 2px 20px rgba(0, 0, 0, 0.06);
   margin: 10px 0px;
   border-radius: 7px;
   transition: transform 300ms ease-out;
   @media screen and (max-width: 768px) {
      width: 65%;
      flex: 0 0 auto;
      gap: 10px;
   }
`;

export const BlogCardBody = styled.div`
   min-height: 200px;
   display: grid;
   cursor: pointer;

   .category {
      font-weight: 600;
      font-size: 14px;
      line-height: 32px;
      letter-spacing: 1px;
      color: #264ffa;
      margin-bottom: 8px;
      text-transform: uppercase;
   }

   .heading-four {
      font-weight: 600;
      font-size: 17px;
      color: #444444;
      margin: 15px 0;

      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      height: fit-content;
      white-space: normal;
   }

   .body-main-text {
      font-weight: 400;
      font-size: 14px;
      margin-bottom: 10px;

      color: #666666;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      height: fit-content;
      white-space: normal;
   }
`;

export const BlogCardFooter = styled.div`
   align-self: flex-end;
   cursor: pointer;
   button {
      padding: 2px;
      border-radius: 8px;
   }

   .duration {
      color: ${({ theme }) => theme.colors?.primary};
      text-decoration: underline;
      font-weight: 500;
      font-size: 14px;
   }
`;

export const BlogBanner = styled.div`
   background: #3678e5;
   width: 100%;
   height: 363px;
   display: flex;
   justify-content: center;
   .contents {
      .center-header {
         width: 1000px;
         margin-top: 100px;
         .header {
            font-weight: 700;
            font-size: 32px;
            line-height: 38px;
            text-align: center;
            color: #ffffff;
         }
      }
      .flex-search {
         width: 100%;
         display: flex;
         justify-content: center;
         .search-component {
            width: 692px;
            display: flex;
            margin-top: 120px;
            border: 1px solid rgba(165, 173, 192, 0.2);
            input {
               border-top-left-radius: 8px;
               border-bottom-left-radius: 8px;
            }
            button {
               background-color: #2cb57a;
               border: none;
               color: #fff;
               border-radius: 0px 8px 8px 0px;
               &:disabled {
                  background-color: #2cb57a !important;
                  border: none;
                  color: #fff !important;
                  cursor: not-allowed;
               }
               &:focus {
                  outline: none;
               }
            }
         }
      }
   }
   @media screen and (max-width: 768px) {
      height: 256px;
      .contents {
         padding: 2rem;
         .center-header {
            width: 95%;
            margin-top: 30px;
            .header {
               font-size: 20px;
               text-align: left;
               line-height: 32px;
            }
         }
         .flex-search {
            .search-component {
               width: 95%;
               margin-top: 24px;
            }
         }
      }
   }
`;
export const NewBlogCardImage = styled.div<{ url?: string }>`
   height: 320px;
   width: 100%;
   border-radius: 8px;
   background-image: url(${({ url }) => url && url});
   background-position: center;
   background-size: cover;
`;

export const NewBlogCardContainer = styled.div`
   width: 100%;
   cursor: pointer;

   .category {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 20px;
      h3 {
         font-weight: 600;
         font-size: 16px;
         line-height: 32px;
         letter-spacing: 1px;
         color: #3678e5;
         text-transform: uppercase;
      }
      .date {
         font-weight: 400;
         font-size: 14px;
         line-height: 21px;
         color: #3d414b;
      }
   }
   .title-area {
      display: flex;
      justify-content: space-between;
      margin-top: 16px;
      align-items: flex-start;
      h3 {
         font-weight: 600;
         font-size: 20px;
         line-height: 30px;
         letter-spacing: 0.25px;
         color: #151f34;
         width: 70%;
      }
      .reactions {
         width: 25%;
         display: flex;
         gap: 27px;
         align-items: center;
         margin-top: 4px;
         font-weight: 400;
         font-size: 14px;
         line-height: 21px;
         color: #4a4a5b;
         .likes {
            display: flex;
            gap: 5px;
            align-items: center;
         }
         .comments {
            display: flex;
            gap: 5px;
            align-items: center;
         }
      }
   }
   .content {
      font-weight: 400;
      font-size: 16px;
      line-height: 32px;
      letter-spacing: 0.25px;
      color: #3d414b;
      margin-top: 16px;
      span {
         font-weight: 600;
         font-size: 14px;
         line-height: 21px;
         color: #3d414b;
         margin-left: 5px;
      }
      .blue-dot {
         display: inline-block;
         width: 8px;
         height: 8px;
         background: #3678e5;
         border-radius: 50%;
         margin-left: 5px;
      }
   }

   @media screen and (max-width: 768px) {
      .title-area {
         width: 100%;
         flex-direction: column;
         h3 {
            width: 100%;
         }
         .reactions {
            width: 100%;
         }
      }
   }
`;

export const FlexCategory = styled.div`
   width: 100%;
   margin-top: 100px;
   margin-bottom: 86px;
   .articles {
      width: 100%;
      display: flex;
      justify-content: space-between;
      .main {
         width: 48%;
      }
      .similar {
         width: 42%;
         display: flex;
         flex-direction: column;
         gap: 10px;
      }
   }

   @media screen and (max-width: 768px) {
      .articles {
         flex-direction: column;
         .main {
            width: 100%;
         }
         .similar {
            width: 100%;
            gap: 10px;
         }
      }
   }
`;

export const CategoryImage = styled.div<{ url?: string }>`
   height: 145px;
   width: 30%;
   border-radius: 8px;
   background-image: url(${({ url }) => url && url});
   background-position: center;
   background-size: cover;
`;

export const CategoryCardContainer = styled.div`
   width: 100%;
   display: flex;
   gap: 22px;
   overflow: hidden;
   cursor: pointer;
   flex-shrink: 0;
   .content {
      width: 65%;
      text-align: left;
      .category {
         font-weight: 600;
         font-size: 14px;
         line-height: 20px;
         letter-spacing: 1px;
         color: #3678e5;
         text-transform: uppercase;
      }
      .title {
         margin-top: 2px;
         font-weight: 600;
         font-size: 18px;
         line-height: 20px;
         color: #444444;
      }
      .text {
         margin-top: 4px;
         font-weight: 400;
         font-size: 14px;
         line-height: 20px;
         letter-spacing: 0.25px;
         color: #3d414b;
      }
   }

   @media screen and (max-width: 768px) {
      .image {
         width: 40%;
      }
      .content {
         width: 58%;
         .category {
            font-size: 13px;
            line-height: 15px;
         }
         .title {
            font-size: 15px;
            line-height: 20px;
         }
         .text {
            font-size: 10px;
            line-height: 15px;
         }
      }
   }
`;

export const MoreBlogContainer = styled.div`
   width: 50%;
   margin-bottom: 30px;
   .flex-category {
      width: 100%;
      display: flex;
      justify-content: space-between;
      .image-content {
         width: 35%;
         .img-container {
            width: 100%;
            height: 226px;
            border-radius: 8px;
         }
      }
      .text-content {
         width: 62%;
         .category {
            font-weight: 600;
            font-size: 14px;
            line-height: 32px;
            letter-spacing: 1px;
            color: #3678e5;
            text-transform: uppercase;
         }
         .title {
            margin-top: 5px;
            font-weight: 600;
            font-size: 20px;
            line-height: 30px;
            color: #444444;
         }
         .content {
            margin-top: 4px;
            font-weight: 400;
            font-size: 16px;
            line-height: 20px;
            letter-spacing: 0.25px;
            color: #3d414b;
         }
      }
   }
   @media screen and (max-width: 768px) {
      width: 100%;
      margin-top: 30px;
      .heading {
         font-weight: 600;
         font-size: 18px;
         line-height: 20px;
      }
      .flex-category {
         .image-content {
            width: 42%;
         }
         .text-content {
            width: 56%;
            .category {
               font-size: 12px;
               line-height: 18px;
            }
            .title {
               font-size: 15px;
               line-height: 20px;
            }
            .content {
               font-size: 10px;
               line-height: 15px;
            }
         }
      }
   }
`;

export const CategoryPageCardContainer = styled.div`
   width: 48%;
   display: flex;
   gap: 22px;
   overflow: hidden;
   margin-bottom: 74px;
   cursor: pointer;
   .image {
      height: 145px;
      width: 30%;
      border-radius: 8px;
   }
   .content {
      width: 65%;
      text-align: left;
      .category {
         font-weight: 600;
         font-size: 14px;
         line-height: 20px;
         letter-spacing: 1px;
         color: #3678e5;
         text-transform: uppercase;
      }
      .title {
         margin-top: 2px;
         font-weight: 600;
         font-size: 18px;
         line-height: 20px;
         color: #444444;
      }
      .text {
         margin-top: 4px;
         font-weight: 400;
         font-size: 14px;
         line-height: 20px;
         letter-spacing: 0.25px;
         color: #3d414b;
      }
   }

   @media screen and (max-width: 768px) {
      width: 100%;
      margin-bottom: 30px;
      .image {
         width: 40%;
      }
      .content {
         width: 58%;
         .category {
            font-size: 13px;
            line-height: 15px;
         }
         .title {
            font-size: 15px;
            line-height: 20px;
         }
         .text {
            font-size: 10px;
            line-height: 15px;
         }
      }
   }
`;

export const AuthorInfoContainer = styled.div`
   display: flex;
   margin-bottom: 1rem;
   margin-top: 1.5rem;
`;

export const AuthorName = styled.h3`
   font-size: 1.7rem;
   font-weight: bold;
   color: #333;
   margin-right: 1rem;
`;

export const AuthorDesignation = styled.p`
   font-size: 1.5rem;
   color: #666;
`;

export const LinkedInIcon = styled.a`
   color: #0077b5;
   font-size: 1.5rem;
   margin-left: 3rem;
`;
